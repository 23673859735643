<template>
  <div class="verifyCodeButton">
    <el-button class="pmy" v-if="time<=0" @click="send" type="primary">获取验证码</el-button>
    <el-button class='def' v-else disabled>{{time}}S重新获取</el-button>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      time: 0,
      isgt: false
    }
  },
  methods: {
    send () {
      this.$tencentCaptcha(val => {
        this.$emit('send', {
          cbk: () => {
            this.time = 60
            this.timeout()
          },
          val
        })
      })
    },
    timeout () {
      if (this.time > 0) {
        setTimeout(() => {
          this.time--
          this.timeout()
        }, 1000)
      }
    }
  }
}
</script>

<style lang="scss">
.verifyCodeButton {
  margin-left: 10px;
  .el-button {
    border-radius: 2px;
    width: 96px;
    flex-shrink: 0;
    line-height: 30px;
    padding: 0;

    border-radius: 2px;
    &.pmy {
      background: #4873f6;
      border-color: #4873f6;
      &:hover {
        opacity: 0.9;
      }
    }
    &.def.is-disabled,
    &.def.is-disabled:hover {
      background: #ebebeb;
      border-color: #ebebeb;
      color: #a6a6a6;
    }
  }
}
</style>
