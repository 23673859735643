<template>
  <div>
    <el-dialog title="管理员实名" :visible.sync="realNameVisible" width="512px" :before-close="realNameClose" class="realNameDialog">
      <div class="realName">
        <span>管理员身份证号</span>
        <el-input v-model="administrator" maxlength="18" placeholder="请输入管理员身份证号" size="medium" :class="numberError?'numberError':''" @change="idNumber"></el-input>
        <p v-if="numberError">{{numberError}}</p>
      </div>
      <div slot="footer" class="realName-footer">
        <el-button @click="realNameClose" size="mini">取 消</el-button>
        <el-button type="primary" @click="continuingCertification" size="mini" :loading="isRealName">确 定</el-button>
      </div>
    </el-dialog>

    <div class="warningBox" v-if="warningVisible">
      <el-alert :title="warningMsg" @close="warningClose" type="warning" show-icon class="warningMsg"></el-alert>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request'
import { isIDcard } from '@/utils/validate'
export default {
  data () {
    return {
      realNameVisible: false,
      administrator: '',
      warningVisible: false,
      warningMsg: '',
      numberError: '',
      isIDcard: isIDcard,
      isRealName: false
    }
  },
  props: ['isPersonAuth'],
  methods: {
    realNameClose () {
      this.realNameVisible = false
      this.isRealName = false
      this.administrator = ''
      this.numberError = ''
    },
    warningClose () {
      this.warningVisible = false
    },
    idNumber () {
      if (!this.isIDcard(this.administrator)) {
        this.numberError = '请输入正确的管理员身份证号'
        return false
      } else {
        this.numberError = ''
      }
    },
    continuingCertification () {
      if (this.administrator === '') {
        this.numberError = '请输入管理员身份证号'
        return false
      } else if (!this.isIDcard(this.administrator)) {
        this.numberError = '请输入正确的管理员身份证号'
        return false
      } else {
        this.numberError = ''
      }
      this.isRealName = true
      this.$api.getAuthUrl({
        "isPersonAuth": this.isPersonAuth,
        "haveAgentAuth": false,
        "myAccountInfo": true,
        'isPortalAuth': true,
        userIdentNo: this.administrator,
        userIdentType: '0'
      }).then((res) => {
        if (res.code === '0') {
          request({
            url: res.data.longUrl,
            method: 'get',
            params: {}
          }).then(res => {
            window.location.href = res.data.redirectUrl
          })
        } else {
          this.realNameClose()
          this.warningVisible = true
          this.warningMsg = res.msg
        }

      })
    },
  }
}
</script>

<style lang="scss" scoped>
.realNameDialog {
  ::v-deep .el-dialog__header {
    padding: 0;
    margin: 0 24px;
    border-bottom: 1px solid #dee2e8;
    height: 42px;
    line-height: 42px;
    font-size: 14px;
    font-weight: bold;
    color: #303b50;
  }
  ::v-deep .el-dialog__headerbtn {
    top: 12px;
  }
}
.realName {
  span {
    display: inline-block;
    width: 145px;
    padding-left: 30px;
  }
  p {
    position: absolute;
    left: 166px;
    font-size: 14px;
    color: #ff5846;
  }
}
.el-dialog {
  .realName .el-input {
    width: 282px !important;
    margin-bottom: 8px;
  }
  .numberError {
    ::v-deep .el-input__inner {
      border-color: #ff5846;
    }
  }
}
.realName-footer {
  text-align: center;
}
</style>