<template>
  <div class="accountContent" v-loading="accoutLoad">
    <div class="returnCrumbs">
      <router-link :to="{name:'personHome'}">个人中心</router-link>
      <span>></span>
      <el-button type="text" class="crumbsActive">用户信息</el-button>
    </div>
    <div class="userInstall clearfix">
      <div class="userCorrelation">
        <div class="administratorInfo">
          <div class="isCertified">
            <img :src="require(`@/assets/image/personCenter/${verified?'certified':'notCertified'}.png`)">
          </div>
          <dl>
            <dt>
              <span>{{userName || '管理员信息'}}</span>
              <el-button type="text" v-if="verified" @click="change_type='userName';verify_mobile_dialog_show=true">[更换]</el-button>
              <el-button type="text" v-if="verified" @click="change_type='userName';verify_mobile_dialog_show=true">[解绑]</el-button>
            </dt>
            <!-- <dd>{{personalData.bindCustomer?'企业用户':'普通用户'}}</dd> -->
          </dl>
          <el-button type="primary" size="small" v-if="!verified" @click="getPersonAuth">管理员认证</el-button>
        </div>
        <p><span>证件类型</span><i>{{personalData.identNo?'中国居民身份证': '- -'}}</i></p>
        <p><span>身份证号</span><i>{{personalData.identNo || '- -'}}</i></p>
        <p><span>认证通过时间</span><i>{{personalData.authPassDate || '- -'}}</i></p>
      </div>
      <div class="logonInstall">
        <h3>登录设置</h3>
        <h5>用于登录当前账号</h5>
        <p>
          <span>手机号</span>
          <u>{{mobile}}</u>
          <i @click="showHidden"><svg-icon :icon-class="isEncryption?'visible':'invisible'" /></i>
          <el-button type="text" @click="change_type='mobile';verify_mobile_dialog_show=true">[修改]</el-button>
        </p>
        <p>
          <span>邮箱</span>{{email || '- -'}}
          <el-button type="text" @click="change_type='email';verify_mobile_dialog_show=true"><span v-if="!personalData.email">[绑定]</span><span v-else>[修改]</span></el-button>
        </p>
        <p><span>注册时间</span>{{personalData.createdDate}}</p>
      </div>
    </div>
    <el-dialog :title="`${change_type=='mobile'?'原':''}${'管理员手机号验证'}`" destroy-on-close @close="dialogClose" :close-on-click-modal="false" :visible.sync="verify_mobile_dialog_show" width="580px">
      <div class="verifyMobileBox">
        <div class="warn" v-if="verify_mobile_sended">
          为了保护帐号安全，需要进行短信验证，我们已发送一条验证码至 {{mobile}}
        </div>
        <div class="hint">
          请输入 {{mobile}}收到的验证码
        </div>
        <div class="inputBox" :class="{err:verify_code_err}" :data-err="verify_code_err">
          <el-input v-model="verify_code" maxlength="6" @blur="checkVerifyCode" placeholder="请输入验证码">
          </el-input>
          <verifyCodeButton @send="sendVerifyCode"></verifyCodeButton>
        </div>
      </div>
      <span slot="footer" class="dialog-buttons">
        <el-button type="primary" @click="verifyMobile" size="small">{{ (change_type=='userName') ? '确定解绑管理员' : '下一步'  }}</el-button>
      </span>
    </el-dialog>

    <el-dialog @close="dialogClose" destroy-on-close :title="`${change_type=='email'&&!email?'绑定':'更换'}${change_type=='mobile'?'手机号':'邮箱'}`" :close-on-click-modal="false"
      :visible.sync="new_info_dialog_show" width="580px">
      <div class="new_info">
        <div class="steps">
          <div :class="{active:new_info_step==1,prev:new_info_step>1}">
            <div class="num">1</div>
            <i class="icon el-icon-circle-check"></i>
            <div>{{`输入${change_type=='mobile'?'手机号':'邮箱'}`}}</div>
          </div>
          <div class="line" :class="{prev:new_info_step>1}"></div>
          <div :class="{active:new_info_step==2,prev:new_info_step>2}">
            <div class="num">2</div>
            <i class="icon el-icon-circle-check"></i>
            <div>{{`验证${change_type=='mobile'?'手机号':'邮箱'}`}}</div>
          </div>
          <div class="line" :class="{prev:new_info_step>2}"></div>
          <div :class="{active:new_info_step==3}">
            <div class="num">3</div>
            <div>完成</div>
          </div>
        </div>
        <div v-if="new_info_step==1">
          <div class="hint">{{`请输入${change_type=='mobile'?'手机号':'邮箱'}`}}</div>
          <div class="inputBox" :class="{err:new_info_val_err}" :data-err="new_info_val_err">
            <el-input v-model="new_info_val" :maxlength="change_type=='mobile'?11:40" @blur="checkNewInfoVal()" :placeholder="`请输入${change_type=='mobile'?'手机号':'邮箱'}`">
            </el-input>
          </div>
        </div>
        <div v-if="new_info_step==2">
          <div class="hint">{{`请输入 ${new_info_val} 收到的验证码`}}</div>
          <div class="inputBox" :class="{err:verify_code_err}" :data-err="verify_code_err">
            <el-input v-model="verify_code" maxlength="6" @blur="checkVerifyCode" placeholder="请输入验证码">
            </el-input>
            <verifyCodeButton @send="sendNewInfoVerifyCode"></verifyCodeButton>
          </div>
        </div>
        <div v-if="new_info_step==3" class="finish">
          <img src="@/assets/image/personCenter/accomplish.png" alt="" srcset="">
          更换成功
        </div>
      </div>
      <span slot="footer" class="dialog-buttons">
        <el-button type="primary" @click="nextStep" size="small">{{new_info_step==3?'确定':'下一步'}}</el-button>
      </span>
    </el-dialog>

    <personInfo ref="personInfo" :isPersonAuth="isPersonAuth" />
  </div>
</template>

<script>
import personInfo from '@/views/userInfo/account/personInfo.vue';
import verifyCodeButton from '@/views/personCenter/components/verifyCodeButton'
import { isEmail, isMobile, isVercode, validName, validIDcard } from '@/utils/validate'
export default {
  data () {
    return {
      companyName: '',
      personalData: {},
      encryptionMobile: '',
      isEncryption: false,
      accoutLoad: false,
      new_info_dialog_show: false,
      new_info_step: 1,
      new_info_val: '',
      new_info_val_err: false,
      new_info_verify_code: '',
      verify_userName_dialog_show: false,
      verify_mobile_dialog_show: false,
      verify_mobile_sended: false,
      verify_code: '',
      verify_code_err: false,
      change_type: 'mobile',
      verify_code_token: '',
      currentVerifyToken: '',
      mobile: '',
      email: '',
      userName: '',
      verified: false,
      certificationForm: {},
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          {
            validator: validName,
            message: '请输入真实姓名',
            trigger: 'blur'
          }
        ],
        identNo: [
          { required: true, message: '请输入证件号码', trigger: 'blur' },
          {
            validator: validIDcard,
            message: '请输入正确的18位身份证号码',
            trigger: 'blur'
          }
        ]
      },
      isPersonAuth: ''
    }
  },
  created () {
    this.getAuth()
  },
  components: {
    verifyCodeButton,
    personInfo
  },
  methods: {
    // 初始化数据
    getAuth () {
      this.accoutLoad = true
      this.$api.getAccountInfo().then(res => {
        this.accoutLoad = false
        this.personalData = res.data
        this.mobile = this.personalData.mobile
        this.encryptionMobile = res.data.mobile
        this.email = this.personalData.email
        this.userName = this.personalData.name
        this.verified = this.personalData.verified
      })
    },
    showHidden () {
      if (this.isEncryption) {
        this.mobile = this.encryptionMobile
      } else {
        this.mobile = this.$store.state.user.userMobile
      }
      this.isEncryption = !this.isEncryption
    },
    // 弹框关闭
    dialogClose () {
      this.new_info_val_err = false
      this.verify_code_err = false
      this.verify_mobile_sended = false
      this.verify_code = ''
      this.new_info_val = ''
      this.new_info_step = 1
    },
    // 手机发送验证码
    sendVerifyCode ({ cbk, val }) {
      let fn =
        this.change_type === 'userName'
          ? this.$api.sendPersonUnbindSend
          : this.$api.sendCurrentSmsCode
      fn(val).then(res => {
        this.verify_mobile_sended = true
        this.verify_code_token = res.data.sms_token
        cbk()
      })
    },
    // 邮箱或手机发送验证码
    sendNewInfoVerifyCode ({ cbk, val }) {
      val[this.change_type] = this.new_info_val
      let fn =
        this.change_type === 'mobile'
          ? this.$api.sendAccountMobile
          : this.$api.sendAccountEmail
      fn(val).then(res => {
        this.verify_code_token =
          this.change_type === 'mobile'
            ? res.data.sms_token
            : res.data.email_token
        cbk()
      })
    },
    // 验证码校验
    checkVerifyCode () {
      let code = this.verify_code
      if (code.length === 0) {
        this.verify_code_err = '请输入验证码'
        return false
      } else if (!isVercode(code)) {
        this.verify_code_err = '请输入正确的验证码'
        return false
      }
      this.verify_code_err = false
      return true
    },
    // 更换手机号或邮箱格式校验与重复性检测
    checkNewInfoVal (bc) {
      let val = this.new_info_val
      let str = this.change_type === 'mobile' ? '手机号' : '邮箱'
      if (val.length === 0) {
        this.new_info_val_err = '请输入' + str
        return false
      } else if (
        this.change_type === 'mobile' ? !isMobile(val) : !isEmail(val)
      ) {
        this.new_info_val_err = '请输入正确的' + str
        return false
      }
      this.new_info_val_err = ''
      if (this.change_type === 'mobile') {
        this.$api.getMobileVerify({ mobile: val }).then(res => {
          result(res)
        })
      } else {
        this.$api.getEmailVerify({ email: val }).then(res => {
          result(res)
        })
      }
      var result = res => {
        if (res.code === '0') {
          if (bc) bc()
        } else if (res.code === '1') {
          this.new_info_val_err = `该${str}已被其它帐号绑定，请更换${str}重试`
        } else {
          this.$alert(res.msg, '温馨提示', {
            confirmButtonText: '确定',
            callback: () => { }
          })
        }
      }
    },
    // 手机号验证码提交
    verifyMobile () {
      if (this.checkVerifyCode()) {
        let verificationCode = this.verify_code
        let fn =
          this.change_type === 'userName'
            ? this.$api.sendPersonUnbind
            : this.$api.validCurrentSmsCode

        fn({
          verificationCode,
          smsToken: this.verify_code_token
        }).then(res => {
          this.verify_mobile_dialog_show = false
          this.verify_mobile_sended = false
          if (this.change_type === 'userName') {
            this.getAuth()
          } else {
            this.currentVerifyToken = res.data.currentVerifyToken
            this.new_info_dialog_show = true
          }
        })
      }
    },
    //  更换手机号或邮箱  下一步
    nextStep () {
      let step = this.new_info_step

      if (step === 1) {
        this.checkNewInfoVal(() => {
          this.new_info_step = 2
        })
      }
      if (step === 2) {
        if (this.checkVerifyCode()) {
          this.changeInfo()
        }
      }
      if (step === 3) {
        this.new_info_dialog_show = false
      }
    },
    // 更换手机号或邮箱   提交
    changeInfo () {
      let update
      let params = {
        verCode: this.verify_code,
        currentVerifyToken: this.currentVerifyToken
      }
      if (this.change_type === 'mobile') {
        params = {
          ...params,
          newMobile: this.new_info_val,
          smsToken: this.verify_code_token
        }
        update = this.$api.updateMobile
      } else {
        params = {
          ...params,
          newEmail: this.new_info_val,
          emailToken: this.verify_code_token
        }
        update = this.$api.updateEmail
      }
      update(params).then(res => {
        this.new_info_step = 3
        if (this.change_type === 'mobile') {
          this.$store.commit('user/setUserMobile', this.new_info_val)
          this.mobile = res.data
        } else {
          this.email = res.data
        }
        this.getAuth()
      })
    },
    // 更换经办人 提交
    verifyUserName () { },
    // 经办人实名
    getPersonAuth () {
      this.$bus.$on('afterLogin', () => {
        this.getAuth()
      })
      this.completeInfo(true)
    },
    completeInfo (item) {
      this.isPersonAuth = item
      this.$refs.personInfo.realNameVisible = true
    },
  }
}
</script>

<style lang="scss" scoped>
.accountContent {
  height: 100%;
  padding: 9px 40px;
  background: #fff;
  ::v-deep.el-dialog {
    .el-dialog__header {
      margin: 0px 20px 0px;
      padding: 16px 0 18px;
      border-bottom: 1px solid #dee2e8;
      .el-dialog__title {
        height: 19px;
        line-height: 19px;
        font-size: 14px;
        font-weight: 600;
      }
    }
    .el-input {
      width: 100%;
      .el-input__inner {
        height: 38px;
        border-radius: 2px;
      }
    }
    .inputBox {
      width: 100%;
      position: relative;
      display: flex;
      &.err {
        .el-input__inner {
          border-color: #ff5846;
          position: relative;
        }
        &::after {
          content: attr(data-err);
          position: absolute;
          left: 0;
          line-height: 12px;
          bottom: -20px;
          font-size: 12px;
          color: #ff5846;
        }
      }
    }
    .el-form {
      width: 100%;
    }
    .warn {
      color: #ff5846;
      line-height: 26px;
    }
    .hint {
      line-height: 14px;
      margin: 16px 0;
      color: #303b50;
    }
  }
}
.userInstall {
  display: flex;
  height: 269px;
  padding: 16px 27px;
  border: 1px solid #dee0e9;
}
.userCorrelation {
  flex-shrink: 0;
  width: 552px;
  padding: 16px 40px 16px 0;
  position: relative;
  &::before {
    content: '';
    width: 1px;
    height: 237px;
    background: #dee0e9;
    position: absolute;
    top: 0;
    right: 0;
  }
  p {
    font-size: 14px;
    color: #303b50;
    line-height: 19px;
    margin-bottom: 24px;
    padding-left: 5px;
    &:last-child {
      margin-bottom: 0;
    }
    span {
      display: inline-block;
      width: 116px;
      color: #6c737b;
    }
  }
}
.administratorInfo {
  margin-bottom: 40px;
  display: flex;
  position: relative;
  dl {
    padding-top: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  dt {
    font-size: 16px;
    color: #303b50;
    line-height: 21px;
    margin-bottom: 8px;
  }
  dd {
    width: 67px;
    height: 24px;
    background: #ffffff;
    border-radius: 15px;
    border: 1px solid #1c67f3;
    font-size: 12px;
    color: #1c67f3;
    line-height: 24px;
    text-align: center;
  }
  span {
    margin-right: 8px;
  }
  .el-button--text {
    padding: 0 0 0 8px;
    position: relative;
    &::before {
      content: '';
      width: 1px;
      height: 11px;
      background: #d8d8d8;
      position: absolute;
      top: 4px;
      left: 0;
    }
    &:last-child {
      padding: 0;
      &::before {
        content: none;
      }
    }
  }
  .el-button--primary {
    padding: 7px 15px;
    position: absolute;
    right: 0;
    font-size: 12px;
    line-height: 16px;
  }
}
.isCertified {
  height: 60px;
  position: relative;
  img {
    width: 70px;
    margin-right: 16px;
  }
}

.logonInstall {
  flex: 1;
  padding: 16px 40px;
  h3 {
    font-size: 16px;
    color: #303b50;
    line-height: 21px;
    font-weight: normal;
  }
  h5 {
    font-size: 12px;
    color: #6c737b;
    line-height: 16px;
    margin: 16px 0 47px;
    font-weight: normal;
  }
  p {
    width: 289px;
    font-size: 14px;
    color: #303b50;
    line-height: 19px;
    margin-bottom: 24px;
    position: relative;
    user-select: none;
    &:last-child {
      margin-bottom: 0;
    }
    > span {
      display: inline-block;
      width: 88px;
      color: #6c737b;
    }
  }
  u {
    display: inline-block;
    width: 91px;
    text-decoration: none;
  }

  .el-button {
    padding: 0;
    position: absolute;
    right: 0;
  }
  i {
    margin: 0 49px 0 17px;
    cursor: pointer;
  }
}
.verifyMobileBox {
  padding: 18px 82px;
  font-size: 14px;
  font-weight: 500;
}
.new_info {
  padding: 18px 82px;
  .steps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    height: 52px;
    font-size: 14px;
    color: #aeb5be;
    & > div {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      &.active {
        color: #333333;
        .num {
          background: #4873f6;
          border: 1px solid #4873f6;
          color: #fff;
        }
      }
      &.prev {
        color: #727888;
        .icon {
          display: block;
        }
        .num {
          display: none;
        }
      }
    }
    .line {
      width: 100%;
      height: 1px;
      background: #dee2e8;
      margin: 0 6px;
      flex-shrink: 1;
      &.prev {
        background: #4873f6;
      }
    }
    .num {
      width: 20px;
      height: 20px;
      background: none;
      border: 1px solid #dee2e8;
      color: #dee2e8;
      line-height: 18px;
      text-align: center;
      border-radius: 50%;
      margin-right: 6px;
      flex-shrink: 0;
    }
    .icon {
      font-size: 20px;
      display: none;
      color: #4873f6;
      margin-right: 6px;
      flex-shrink: 0;
    }
  }
  .finish {
    text-align: center;
    color: #000000;
    img {
      width: 36px;
      height: 36px;
      margin: 16px auto;
      display: block;
    }
  }
}
.verifyCodeButton {
  margin-top: 3px;
}
</style>